import React from "react";

/**Import Styles*/
import styles from "./styles/index.module.scss";

/**Import Components */
import Title from "./components/Title";
import About from "./components/About";
import Brands from "./components/Brands";
import Work from "./components/Work";
import MemberShip from "./components/MemberShip";
import Footer from "./components/Footer";

export default function LandingPage() {
    return (
        <section className={styles.landingPage}>
            <Title />
            <About />
            <Brands />
            <Work />
            <MemberShip />
            <Footer />
        </section>
    )
}