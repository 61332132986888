import React from "react";
import { Link } from "react-router-dom";

/**Import Styles*/
import styles from "../styles/title.module.scss";
import mobile from "../images/mobile.svg"

/**Import Packaged Component*/
import { BsFillPlayFill } from 'react-icons/bs';
import Grid2 from '@mui/material/Unstable_Grid2';


export default function Title() {
    return (
        <section className={styles.title}>
            <Grid2 container>
                <Grid2 md={7}>
                    <h1>WELCOME TO CIRGLE</h1>
                    <p>
                        is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
                    </p>
                    <div className={styles.search}>
                        <input type="text" placeholder="Enter your work email" />
                        <div className={styles.button}>
                            <Link to="sign-in">
                                Get Started
                            </Link>
                        </div>

                    </div>
                    <div className={styles.video}>
                        <div className={styles.button}>
                            <BsFillPlayFill />
                        </div>
                        <div>
                            <h5>Watch Video</h5>
                        </div>

                    </div>
                </Grid2>
                <Grid2 className={styles.imageTile}>
                    <img src={mobile} alt="Image" className={styles.mobileImage} />
                </Grid2>
            </Grid2>

        </section>
    )
}