import moment from "moment";

export const dateToFormattedString = (date) => {
    const moment_date = moment(new Date());
    const input_date = moment(date);

    const isToday = moment_date.clone().startOf('day').isSame(input_date, "day");
    const yesturday = moment_date.clone().startOf('day').subtract(1, 'days').isSame(input_date, "day");
    if (isToday) {
        return "Today";
    }
    else if (yesturday) {
        return "Yesterday";
    }
    else {
        return moment_date.format('MMM DD,YYYY');
    }

}

export const removeSpecialCharacter = (str) => {

    return str.replace(/[^a-zA-Z0-9 ]/g, '');

}


