import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


/**Import Styles and Assets */
import styles from "./ResetPassword.module.scss";
import logo from "./images/logo.png"

/**Import Packaged Component*/
import Grid from '@mui/material/Grid';
import {  TextInput } from "../../components/Fields";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';


/**Import Validation */
import { resetPasswordSchema } from "../../Validation";
import { postForgetpasswordThunk } from "../../features/profileReducer";


export default function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleSubmit, control, formState: { errors }, } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(resetPasswordSchema)
    });

    const [isSubmitted, setIsSubmitted] = useState(false);


    function onSubmit(data) {
        setIsSubmitted(true);
        dispatch(postForgetpasswordThunk({ data, navigate, setIsSubmitted }));
    }

    return (
        <div className={styles.signIn}>
            <Grid container>
                <Grid xs={6} className={styles.title}>
                    <div className={styles.content}>
                        <h1>Reset Your Password </h1>
                        <p>More feature are available with premium purchase</p>
                    </div>
                </Grid>
                <Grid xs={6}>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.header}>
                            <Link to={`/`}>
                                <img src={logo} alt="Logo" />
                            </Link>
                            <p>Not Registered Yet</p>
                            <Link to={`/register`}><button>Register</button></Link>
                        </div>

                        <h1>Reset Password</h1>
                        <p>Lets get you all set up so you can verify your personal account and begin Getting up your profile</p>
                        <hr />

                        <div className={styles.fields}>

                            <TextInput
                                name="email"
                                type="text"
                                label="Email"
                                control={control}
                                error={errors}
                            />

                            <div className={styles.description}>
                                <Link className={styles.reset} to={`/sign-in`}>Sign In?</Link>
                            </div>
                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`}>Reset Password<CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Reset Password </button>
                            }
                        </div>
                    </form>
                </Grid >
            </Grid >



        </div >
    )
}