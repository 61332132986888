import React, { useState, useEffect } from "react";

/**Import Styles And Assets */
import styles from "./styles/index.module.scss";
import banner from "./images/Banner.png";

/**Import Customized Component */
import CreateOfferAndPromotion from "./CreateOfferAndPromotion";
import { deletePostThunk, getPostThunk } from "../../features/postReducer";
import { dateToFormattedString } from "../../Prototypes";

/**Import Packaged Component */
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from "react-redux";
import { deleteOfferThunk, getOffersThunk } from "../../features/offersReducer";
import moment from "moment";
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri"
import EditOfferAndPromotion from "./EditOfferAndPromotion";

export default function OfferAndPromotion() {

    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.offers);

    const [isCreateOffer, setIsCreateOffer] = useState(false);
    const [offerToEdit, setOfferToEdit] = useState(null);


    useEffect(() => {
        if (status !== 'success') {
            dispatch(getOffersThunk());
        }

    }, [])

    const handleDeleteButton = (e) => {
        const { value } = e.currentTarget;
        dispatch(deleteOfferThunk({ offer_id: value }))

    }

    const handleEditButton = (e) => {
        const { value } = e.currentTarget;
        setOfferToEdit(data[value]);
    }


    return (

        (offerToEdit !== null) ?
            <EditOfferAndPromotion setOfferToEdit={setOfferToEdit} offerToEdit={offerToEdit} /> :
            isCreateOffer ? <CreateOfferAndPromotion setIsCreateOffer={setIsCreateOffer} /> :
                <div className={styles.offerAndPromotion}>
                    <div className={styles.banner}>
                        <img src={banner} alt="Banner Image" />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <h1 className={styles.header}>Offer And Promotion</h1>
                            <button className={styles.create} onClick={() => setIsCreateOffer(true)}>Upload Offer</button>

                        </div>
                        {!data.length ? (status === 'loading') ? <CircularProgress size={20} className={styles.loader} />
                            : <h3 className={styles.noPost}>No Offers Found</h3> :
                            <Grid container columnSpacing={2} rowSpacing={5} className={styles.cards}>
                                {data.map((item, index) => {
                                    const { id, target_audience, age_group, gender, offer_start_date, offer_end_date, created_date } = item
                                    return <Grid xl={2.4} lg={3} sm={4} xs={12} item key={index} >
                                        <div className={styles.card}>
                                            <div className={styles.cardInfo}>
                                                <div className={styles.top}>
                                                    <p className={styles.tag}>{target_audience}</p>
                                                    <p className={styles.info}>Age Group : <span>{age_group}</span></p>
                                                    <p className={styles.info}>Gender : <span>{gender}</span></p>
                                                    <p className={styles.info}>Start Date : <span>{moment(offer_start_date).format('MMM DD,YYYY')}</span></p>
                                                    <p className={styles.info}>End Date : <span>{moment(offer_end_date).format('MMM DD,YYYY')}</span></p>
                                                </div>
                                                <div className={styles.bottom}>
                                                    <div className={styles.titleAndEditContainer}>
                                                        <p className={styles.header}>{`Offer No ${index + 1}`}</p>
                                                        <div className={styles.editContainer}>
                                                            <button value={id} onClick={handleDeleteButton} >
                                                                <RiDeleteBin6Line />
                                                            </button>
                                                            <button value={index} onClick={handleEditButton}>
                                                                <RiEdit2Line />
                                                            </button>


                                                        </div>
                                                    </div>
                                                    <p className={styles.date}>{dateToFormattedString(created_date)}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Grid>
                                }
                                )}
                            </Grid>
                        }

                    </div >



                </div >

    )
}