import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";


/**Import Styles And Assets*/
import styles from "./styles/MenuModal.module.scss";
import { TextInput } from "../../components/Fields";

/**Import Component */
import Modal from "../../components/Modal";
import { createMenuSchema } from "../../Validation";
import { useCreateMenu } from "./hooks/useCreateMenu";
import { createMenuThunk } from "../../features/menuReducer";


/**Import Package Components */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import _ from 'lodash'
import { Toast } from "../../hooks/useToast";

const CreateMenuModal = ({ setIsMenuModal, setIsUpdated }) => {
    const BUTTON_LABEL = "Create Menu";
    const dispatch = useDispatch();

    const { handleSubmit, control, formState: { errors } } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(createMenuSchema)
    });

    const components = useCreateMenu({ control, errors });

    const [media, setMedia] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);


    const onSubmit = (body) => {
        if (!media.length) {
            return Toast("Menu does not have an empty media", 'error', false);
        }

        setIsSubmitted(true);
        const { menu_category, menu_description, menu_item, menu_new_price, menu_old_price } = body;
        const data = new FormData();
        data.append('menu_item', menu_item)
        data.append('menu_old_price', menu_old_price)
        data.append('menu_category', menu_category)
        data.append('menu_description', menu_description)
        data.append('menu_new_price', menu_new_price)
        data.append('uploadedImages', media[0]);

        dispatch(createMenuThunk({ data, setIsSubmitted, setIsMenuModal, setIsUpdated }));

    }

    const handleSetMedia = (e) => {
        const file = e.target.files;
        setMedia(file);
    }


    return (
        <Modal>
            <div className={styles.mainDiv}>
                <div className={styles.createMenuModal}>
                    <div className={styles.title}>
                        <h1 className={styles.header}>Menu And Service Management</h1>
                        <button className={styles.close} onClick={() => !isSubmitted && setIsMenuModal(false)}>X</button>
                    </div>
                    <hr />
                    <div className={styles.content}>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            {components.map(item => item)}
                            <h3 className={styles.uploadHeader}>Upload Images</h3>
                            {!media.length ?
                                <div className={styles.uploadContainer}>
                                    < Button
                                        variant="contained"
                                        component="label"
                                        className={styles.uploadButton}
                                    >
                                        <IoMdAdd />
                                        <input type="file" accept="image/png,image/jpg,image/jpeg,.png,.jpg,.jpeg" hidden onChange={handleSetMedia} />
                                    </Button>
                                </div> :
                                <div className={styles.uploadContainer}>
                                    <div className={styles.imageContainer}>
                                        <div className={styles.cancel}>
                                            <button onClick={() => !isSubmitted && setMedia([])} type="button">X</button>
                                        </div>
                                        <img src={URL.createObjectURL(media[0])} alt="Uploaded Image" />
                                    </div>

                                </div>
                            }

                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`} type="button">{BUTTON_LABEL}<CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">{BUTTON_LABEL}</button>
                            }

                        </form>
                    </div>
                </div>
            </div >

        </Modal >
    )
}

export default React.memo(CreateMenuModal)