import React, { useState, useEffect } from "react";
import { useOfferAndPromotion } from "./hooks/useOfferAndPromotion";

/**Import Styles And Assets */
import styles from "./styles/CreateOfferAndPromotion.module.scss";

/**Import Customized Component */
import Map from "../../components/Map/MapContainer";
import { createOfferSchema } from "../../Validation";


/**Import Package Component */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { editOfferThunk } from "../../features/offersReducer";
import CircularProgress from "@mui/material/CircularProgress";
import { IoIosArrowRoundBack } from "react-icons/io";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore);


export default function EditOfferAndPromotion({ offerToEdit, setOfferToEdit }) {


    const dispatch = useDispatch();
    const {
        id,
        target_audience,
        location_audience_lon,
        location_audience_lat,
        age_group,
        gender,
        offer_start_date,
        offer_end_date
    } = offerToEdit;


    const { handleSubmit, control, formState: { errors }, watch, setValue } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(createOfferSchema),
        defaultValues: {
            title: target_audience,
            geo_location: JSON.stringify({ lat: location_audience_lat, lng: location_audience_lon }),
            age_group,
            gender
        }
    });

    const geo_locations = watch("geo_location");
    const [timeData, setTimeData] = useState({
        open_time: dayjs(offer_start_date),
        close_time: dayjs(offer_end_date),
    })
    const [isMap, setIsMap] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const components = useOfferAndPromotion({
        control,
        errors,
        geo_locations,
        setIsMap,
        timeData,
        handleTimeChange,
        disableStartTime: dayjs(offer_start_date).isSameOrBefore(new Date()),
        disableEndTime: dayjs(offer_end_date).isSameOrBefore(new Date())
    });


    useEffect(() => {
        setValue('open_time', dayjs(timeData.open_time))
    }, [timeData.open_time])

    useEffect(() => {
        setValue('close_time', dayjs(timeData.close_time))
    }, [timeData.close_time])


    const onSubmit = (data) => {
        setIsSubmitted(true);
        const { lat, lng } = JSON.parse(data.geo_location);
        const body = {
            offer_id: id,
            gender: data.gender,
            age_group: data.age_group,
            target_audience: data.title,
            location_audience_lon: `${lng}`,
            location_audience_lat: `${lat}`,
            offer_start_date: dayjs(data.open_time).format('YYYY-MM-DD'),
            offer_end_date: dayjs(data.close_time).format('YYYY-MM-DD')

        }
        dispatch(editOfferThunk({ body, setIsSubmitted, setOfferToEdit }))

    }


    function handleTimeChange(name, value) {
        setTimeData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    return (
        <div className={styles.offerAndPromotion}>
            <div className={styles.backButtonContainer} onClick={() => setOfferToEdit(null)}>
                <IoIosArrowRoundBack className={styles.icon} />
                <h5 className={styles.back}>Back</h5>
            </div>
            {isMap ? <Map location={geo_locations ? JSON.parse(geo_locations) : {}} setClose={setIsMap} setLatLng={setValue} /> : ""}
            <div className={styles.container}>
                <h1 className={styles.header}>Edit Offers And Promotions</h1>
                <hr />
                <h3 className={styles.subHeader}>Offers And Promotions</h3>
                <p className={styles.subTitle}>Update Your Offers And Promotions</p>
                <hr />
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container columnSpacing={5} rowSpacing={5}>
                        {components.map(({ label, field, type }, ind) => {
                            return <Grid item xl={6} lg={12} xs={12} container key={ind}>
                                {label != '' ? <Grid item xl={4} lg={4.5} xs={4} className={styles.labelContainer}>
                                    <p className={styles.label}>{label}</p>
                                </Grid> : ''}
                                {
                                    (type === 'single') ?
                                        <Grid item xl={7.5} lg={7.5} xs={6}>
                                            {field}
                                        </Grid> :
                                        <Grid item xl={7.5} lg={7.5} xs={6} container columnSpacing={3}>
                                            {field.map((item, ind) => <Grid item xs={6} key={ind}>
                                                {item}
                                            </Grid>
                                            )}
                                        </Grid>

                                }
                            </Grid>
                        }
                        )}

                    </Grid>
                    {isSubmitted ?
                        <button className={`${styles.submit} ${styles.submitted}`} type="button">Save<CircularProgress size={20} /></button>
                        :
                        <button className={styles.submit} type="submit">Save</button>
                    }
                </form>
            </div>
        </div >
    )
}