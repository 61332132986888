import { io } from "socket.io-client";


const SOCKET_URL = 'http://chat.cirgle.retrocubedevs.com:4911';


const socket = io(SOCKET_URL, {
    transports: ['websocket'],
    autoConnect: true
})

export const EVENTS = {
    CREATE_ROOM: "_createRoomWithCb"
}

class SocketIo {
    connectToSocket = () => {
        socket.connect();
        socket.on('connect', () => {
        })
    }
    emit = (eventName, params, onSuccess) => {
        socket.emit(eventName, params, res => {
            onSuccess(res.data);
        })

    }

    dispose = (eventName) => {
        socket.off(eventName);
    }

    disconnect = () => {
        socket.disconnect();
    }
}

export default new SocketIo();