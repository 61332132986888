import React, { useState } from "react";

/**Import Styles And Assets */
import styles from "./ChangePassword.module.scss"

/**Import Components */
import { TextInput } from "../../components/Fields";

/**Import Package Components */
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { changePasswordSchema } from "../../Validation";
import CircularProgress from '@mui/material/CircularProgress';
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { updatePasswordThunk } from "../../features/profileReducer";

export default function ChangePassword() {

    const dispatch = useDispatch();
    const { handleSubmit, control, formState: { errors } } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(changePasswordSchema)
    });



    const [passType, setPassType] = useState("password");
    const [confirmPassType, setConfirmPassType] = useState("password");
    const [newPassType, setNewPassType] = useState("password");
    const [isSubmitted, setIsSubmitted] = useState(false)


    function onSubmit(data) {
        setIsSubmitted(true);
        dispatch(updatePasswordThunk({ data, setIsSubmitted }))
    }

    function handleTypeChange(cb) {
        cb(prev => {
            return (prev === "text") ? "password" : "text"
        })
    }

    return (
        <div className={styles.changePassword}>
            <div className={styles.container}>
                <h1>Change Password</h1>
                <hr />
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2 }}>
                        <Grid xs={12} item>
                            <TextInput
                                name="current_password"
                                type={passType}
                                label="Password"
                                control={control}
                                error={errors}
                                icon={passType === "password" ? <AiFillEye onClick={() => handleTypeChange(setPassType)} />
                                    : <AiOutlineEyeInvisible onClick={() => handleTypeChange(setPassType)} />}
                            />

                            <TextInput
                                name="new_password"
                                type={newPassType}
                                label="New Password"
                                control={control}
                                error={errors}
                                icon={newPassType === "password" ? <AiFillEye onClick={() => handleTypeChange(setNewPassType)} />
                                    : <AiOutlineEyeInvisible onClick={() => handleTypeChange(setNewPassType)} />}
                            />
                            <TextInput
                                name="confirm_password"
                                type={confirmPassType}
                                label="Confirm Password "
                                control={control}
                                error={errors} icon={confirmPassType === "password" ? <AiFillEye onClick={() => handleTypeChange(setConfirmPassType)} />
                                    : <AiOutlineEyeInvisible onClick={() => handleTypeChange(setConfirmPassType)} />}
                            />
                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`} type="button">Change Password<CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Change Password</button>
                            }
                        </Grid>

                    </Grid>
                </form>

            </div >
        </div >
    )
}