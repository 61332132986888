import React, { useState, useEffect } from "react";



/**Import Styles And Assets */
import styles from './styles/index.module.scss';

/**Import Customized Components And Hooks */
import { getReportThunk } from "../../features/reportReducer";


/**Import Packaged Component */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';

export default function ReportManagement() {

    const { data, status } = useSelector(state => state.report);
    const dispatch = useDispatch();




    const [page, setPage] = useState(1);
    const [reportType, setReportType] = useState("business_reports");

    const ROWS_PER_PAGE = 5;
    const PAGINATION_COUNT = Math.ceil(data[reportType]?.length / ROWS_PER_PAGE);
    const REASON_LENGTH = 20;



    useEffect(() => {
        if (status !== 'success') {
            dispatch(getReportThunk());
        }
    }, [])


    useEffect(() => {
        setPage(1);
    }, [reportType])


    const handlePageChange = (e, page) => {
        setPage(page);
    }




    return (
        <div className={styles.reportManagement}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <h1 className={styles.header}>Report Management</h1>
                </div>
                <Select
                    defaultValue="business_reports"
                    className={styles.select}
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                >
                    <MenuItem value="business_reports">Business</MenuItem>
                    <MenuItem value="post_reports">Posts</MenuItem>
                </Select>

                <div className={styles.tableContainer}>

                    <Paper>
                        <TableContainer className={styles.tableComponent}>
                            <Table aria-label="simple table">
                                <TableHead className={styles.tableHeaderContainer}>
                                    <TableRow>
                                        <TableCell >Reporter</TableCell>
                                        <TableCell>Reporter Email</TableCell>
                                        <TableCell>Reason</TableCell>
                                        <TableCell>Created At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {status === 'loading' ?
                                        <TableRow>
                                            <TableCell colSpan={12} className={styles.loaderContainer}>
                                                <CircularProgress size={40} className={styles.loader} />
                                            </TableCell>
                                        </TableRow> :
                                        !data[reportType]?.length ?
                                            <TableRow>
                                                <TableCell colSpan={12} className={styles.emptyDataContainer}>
                                                    <h1 className={styles.emptyData}>No Reports Found</h1>
                                                </TableCell>
                                            </TableRow> :
                                            data[reportType].slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE).map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    className={styles.tableRowContainer}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                    <TableCell>
                                                        <Tooltip title={row.reason}>
                                                            <p className={styles.reason}>{(row.reason.length > REASON_LENGTH)
                                                                ? `${row.reason.slice(0, REASON_LENGTH)}...`
                                                                : row.reason}
                                                            </p>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>{moment(row.created_date).format("MMM DD,YYYY")}</TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack spacing={2} className={styles.paginationContainer}>
                            {(data[reportType]?.length > ROWS_PER_PAGE) ?
                                <Pagination
                                    count={PAGINATION_COUNT}
                                    onChange={handlePageChange}
                                    page={page}
                                    classes={{
                                        root: styles.paginaton
                                    }}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            classes={{
                                                selected: styles.selectedPage
                                            }}
                                            {...item}
                                        />
                                    )}
                                /> : ""
                            }
                        </Stack>
                    </Paper>
                </div>
            </div>
        </div>
    )
}