import React from "react";
import { Link } from "react-router-dom";


/**Import Styles */
import styles from "../styles/navbar.module.scss";


/**Import Assets */
import logo from "../images/logo.png"

export default function Navbar() {

    const links = ["Home", "About", "Terms & Conditions", "Privacy And Policy", "Contact Us"];
    return (
        <section className={styles.navbar}>
            <div className={styles.logo}>
                <img src={logo} alt="Logo " />
            </div>
            <div className={styles.links}>
                {links.map((link, index) => {
                    return <div className={styles.link}>
                        <Link to={link} key={index}>{link.toUpperCase()}</Link>
                    </div>
                }
                )}


            </div>
            <div className={styles.login}>
                <Link to="sign-in" className={styles.button}>
                    Sign In
                </Link>

                <Link to="register" className={`${styles.button} ${styles.signUpButton}`}>
                        Sign Up
                </Link>


            </div>

        </section >
    )
}