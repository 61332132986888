import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dashboard } from '../Api/Dashboard';
import { Toast } from '../hooks/useToast';


export const getDashboardThunk = createAsyncThunk(
    'dashboard/get',
    async (_, { getState }) => {
        try {
            const { id, api_token } = getState().profile.data;
            const response = await Dashboard.get(id, api_token);
            return response.offers[0];
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)



const initialState = {
    status: "pending",
    data: [],
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        updateKey: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getDashboardThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = action.payload;
        },
        [getDashboardThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getDashboardThunk.rejected]: (state, action) => {
            state.status = "exception"
        }
    }
})


export const { updateKey,resetState } = dashboardSlice.actions

export default dashboardSlice.reducer