import React from "react";
import ReactDom from "react-dom";

/**Import Styles And Assets */
import styles from "./Modal.module.scss";



export default function Modal({ children }) {
    return (
        ReactDom.createPortal(
            <div className={styles.modal}>
                <div className={styles.background}></div>
                    {children}

            </div>,
            document.getElementById("modal"))
    )
}