import { Axios } from "../hooks/useAxiosInstance";

export const Notification = (function () {

    const apis = () => ({
        send: async (data) => await sendRecord(data),
    })


    async function sendRecord(data) {
        const url = "api/business/sendTopic";
        const option = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
