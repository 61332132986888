import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Reviews } from '../Api/Reviews';
import { Toast } from '../hooks/useToast';


export const getReviewsThunk = createAsyncThunk(
    'reviews/get',
    async (_, { getState }) => {
        try {
            const { id, api_token } = getState().profile.data;
            const response = await Reviews.get(id, api_token);
            const data = {
                topData: response.topData[0],
                reviewsData: response.reviewsData
            }
            return data;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)




const initialState = {
    status: "pending",
    data: {},
}

export const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getReviewsThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = action.payload;
        },
        [getReviewsThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getReviewsThunk.rejected]: (state, action) => {
            state.status = "exception"
        }

    }
})
export const { resetState } = reviewsSlice.actions
export default reviewsSlice.reducer