import React from "react";

/** Import Components */
import Navbar from "./components/Navbar";

/** Import Styles */
import styles from "./styles/index.module.scss";

export default function PublicLayout({ isNavbar, children }) {

    return (
        <div className={styles.layout}>
            {isNavbar ? <Navbar /> : ""}
            <div className={styles.content}>
                {children}
            </div>

        </div>

    )

}