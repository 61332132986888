import React, { useContext, useEffect } from "react";
import socket from "../Config/Socket";


const SocketContext = React.createContext();

export function useSocket() {
    return useContext(SocketContext);
}


export function SocketProvider({ children }) {

    useEffect(() => {
        socket.connectToSocket();

        return () => socket.disconnect();
    }, [])

    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>
}