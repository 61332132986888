import { Grid } from "@mui/material";
import React from "react";

import styles from "../styles/Work.module.scss";
import arrow from "../images/arrow.png";


export default function Work() {
    return (
        <div className={styles.work}>
            <h1 className={styles.title}>HOW IT WORKS</h1>
            <Grid container rowSpacing={2} columnSpacing={0}>
                <Grid item xs={2}>
                    <div className={`${styles.numberContainer} ${styles.odd}`}>
                        <h1 className={styles.number}>1</h1>

                    </div>
                    <p className={styles.info}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s .
                    </p>

                </Grid>
                <Grid item xs={1.32} className={styles.imageContainer}>
                    <div className={styles.image}>
                        <img src={arrow} alt="Arrow1" />
                    </div>

                </Grid>
                <Grid item xs={2}>
                    <div className={`${styles.numberContainer} ${styles.even}`}>
                        <h1 className={styles.number}>2</h1>

                    </div>
                    <p className={styles.info}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s .
                    </p>

                </Grid>
                <Grid item xs={1.32} className={styles.imageContainer}>
                    <div className={styles.image}>
                        <img src={arrow} alt="Arrow1" />
                    </div>

                </Grid>
                <Grid item xs={2}>
                    <div className={`${styles.numberContainer} ${styles.odd}`}>
                        <h1 className={styles.number}>3</h1>

                    </div>
                    <p className={styles.info}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s .
                    </p>

                </Grid>
                <Grid item xs={1.32} className={styles.imageContainer}>
                    <div className={styles.image}>
                        <img src={arrow} alt="Arrow1" />
                    </div>

                </Grid>
                <Grid item xs={2}>
                    <div className={`${styles.numberContainer} ${styles.even}`}>
                        <h1 className={styles.number}>4</h1>

                    </div>
                    <p className={styles.info}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s .
                    </p>

                </Grid>




            </Grid>

        </div>
    )
}