import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

/**Import Styles And Assets */
import styles from "./styles/index.module.scss";

/**Import Package Component */
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import { getDashboardThunk } from "../../features/dashboardReducer";

export default function Dashboard() {

    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.dashboard)

    const cardsContent = useMemo(() => [
        {
            label: "Manage Your Profile",
            description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
            percentage: getComponent('percentage', 'profile_per'),
            color: "#6268F3",
            fields: [
                <Link to="/manage-profile"><button className={styles.manage}>Manage Profile</button></Link>
            ]

        },
        {
            label: "Total no of CheckIns",
            description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
            percentage: getComponent('number', 'total_checkins'),
            color: "#810CA8",

        }, {
            label: "Total no of Followers",
            description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
            percentage: getComponent('number', 'total_followers'),
            color: "#3C79F5",

        },
        {
            label: "Total no Posts",
            description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
            percentage: getComponent('number', 'total_posts'),
            color: "#2DCDDF",
        },
        {
            label: "Total no of Reports",
            description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
            percentage: getComponent('number', 'total_reports'),
            color: "#1F8A70",
        },
        {
            label: "Ratings",
            description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard",
            percentage: getComponent('number', 'total_rating_by_all_user'),
            color: "#913175",
        }

    ], [status])

    function getComponent(type, key) {
        if (status == 'loading') {
            return <CircularProgress size={60} style={{ color: "#fff" }} />
        }
        if (status == 'exception') {
            if (type === 'percentage') {
                return <CircularProgressWithLabel value={0} />
            }
            else {
                return <h1 className={styles.percentage}>{0}</h1>
            }
        }
        if (type === 'percentage') {
            return <CircularProgressWithLabel value={data[key] ? data[key] : 0} />
        }
        else {
            return <h1 className={styles.percentage}>{data[key] ? data[key] : 0}</h1>
        }
    }

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getDashboardThunk());
        }
    }, [])




    return (
        <div className={styles.dashboard}>
            <Grid container columnSpacing={4} rowSpacing={4} className={styles.container}>
                {cardsContent.map(({ label, description, percentage, color, fields }, ind) => <Grid item lg={6} xs={8} key={ind} className={styles.cardContainer} >

                    <div className={styles.card} style={{ backgroundColor: color }}>
                        <div className={styles.contentContainer}>
                            <h1 className={styles.cardHeader}>{label}</h1>
                            {fields ? fields.map(items => items) : ""}

                        </div>
                        <div className={styles.percentageContainer}>
                            {percentage}
                        </div>
                    </div>


                </Grid>
                )}


            </Grid>

        </div >
    )
}


function CircularProgressWithLabel(props) {

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} size={100}
                classes={{
                    root: styles.circularProgress,
                    circle: styles.circle,
                    circleDeterminate: styles.circlePath
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary" className={styles.progressLabel}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}