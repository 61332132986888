import React, { useEffect } from "react";

/**Import Styles And Assets */
import styles from "./styles/index.module.scss";
import defaultImage from "./images/defaultImage.png";

/**Import Package Component*/
import LinearProgress from '@mui/material/LinearProgress';
import { Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from "react-redux";
import { getReviewsThunk } from "../../features/reviewsReducer";
import CircularProgress from '@mui/material/CircularProgress';
import { dateToFormattedString } from "../../Prototypes";


export default function RatingAndReview() {

    const { data, status } = useSelector(state => state.reviews);
    const dispatch = useDispatch();

    const ratings = [
        {
            number: 5,
            percent: data?.topData?.['5_star'] ?? 0,
        },
        {
            number: 4,
            percent: data?.topData?.['4_star'] ?? 0
        },
        {
            number: 3,
            percent: data?.topData?.['3_star'] ?? 0
        },

        {
            number: 2,
            percent: data?.topData?.['2_star'] ?? 0
        },
        {
            number: 1,
            percent: data?.topData?.['1_star'] ?? 0
        },
    ]

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getReviewsThunk());
        }
    }, [])


    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }



    return (
        <div className={styles.ratingAndReviewManagement}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <h1 className={styles.header}>Rating And Review Management</h1>

                </div>
                <div className={styles.subTitle}>
                    <h3 className={styles.header}>
                        Reviews <span className={styles.count}>
                            ({(status === 'success') ? data.reviewsData.length : (status === 'loading') ? <CircularProgress size={10} /> : 0})
                        </span>
                    </h3>
                </div>

                {(status !== 'success') ?
                    (status === 'loading') ? <div className={styles.loaderContainer}><CircularProgress className={styles.loader} /></div> : ""
                    : <div className={styles.cards}>
                        <div xs={12} className={styles.allRatings}>
                            <div className={styles.ratingHeader}>
                                <h4 className={styles.header}>{round((data.topData.total_rating / data.topData.total_people_rated), 1) ? round((data.topData.total_rating / data.topData.total_people_rated), 1) : 0} Ratings</h4>
                                <div className={styles.ratingContainer}>
                                    <Stack spacing={1}>
                                        <Rating defaultValue={round((data.topData.total_rating / data.topData.total_people_rated), 1)} precision={0.1} readOnly />
                                    </Stack>
                                </div>

                            </div>
                            <hr className={styles.ratingDivider} />
                            <div className={styles.ratingContent}>

                                <Grid container>
                                    {ratings.map(({ number, percent }, ind) => <Grid item xs={7} className={styles.bars} key={ind}>
                                        <p className={styles.barIndex}>{number}</p>
                                        <LinearProgress variant="determinate" value={percent} className={styles.bar} />
                                    </Grid>
                                    )}
                                </Grid>
                            </div>

                        </div>
                        {data.reviewsData.length ? <div xs={12} className={styles.dropdownContainer}>
                            <Select
                                defaultValue={1}
                                className={styles.select}
                            >
                                <MenuItem value={1}>Most Recent</MenuItem>
                                <MenuItem value={2}>Default</MenuItem>
                            </Select>

                        </div> : ""}
                        <div xs={12} className={styles.reviews}>
                            <Grid container columnSpacing={3}>
                                {data.reviewsData.map((item, ind) => {
                                    const { image_url, created_date, rate_description, rate_star, name } = item;
                                    return <Grid item lg={6} xs={12} className={styles.commentDetail} key={ind}>
                                        <div className={styles.userInfo}>
                                            <div className={styles.userImageContainer}>
                                                <img src={image_url} alt="User Image" className={styles.userImage} />
                                            </div>
                                            <div className={styles.info}>
                                                <div className={styles.namedateContainer}>
                                                    <p className={styles.name}>{name}</p>
                                                    <p className={styles.date}>{dateToFormattedString(created_date)}</p>

                                                </div>
                                                <p className={styles.star}><Rating value={rate_star} readOnly /></p>
                                            </div>
                                        </div>
                                        <div className={styles.userComment}>
                                            <p className={styles.commentText}>{rate_description}</p>
                                            {/* <div className={styles.updateButtons}>
                                                <p className={styles.edit}>Edit</p>
                                                <p className={styles.delete}>Delete</p>
                                            </div> */}

                                        </div>
                                        <hr />
                                    </Grid>
                                }
                                )}
                            </Grid>
                        </div>
                    </div>}
            </div>
        </div >
    )
}