import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Menu } from '../Api/Menu';
import { Toast } from '../hooks/useToast';


export const getMenuThunk = createAsyncThunk(
    'menu/get',
    async (_, { getState }) => {
        try {
            const { id, api_token } = getState().profile.data;
            const response = await Menu.get(id, api_token);
            return response.menuCategory;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)

export const createMenuThunk = createAsyncThunk(
    'menu/create',
    async ({ data, setIsSubmitted, setIsMenuModal, setIsUpdated }, { getState }) => {
        Toast("Creating New Menu ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            data.append('business_id', id);
            const response = await Menu.create(data, api_token);
            Toast(response.message, "success", false);
            setIsMenuModal(false);
            return response.menu[0];
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
        finally {
            setIsSubmitted(false);
        }
    }
)

export const editMenuThunk = createAsyncThunk(
    'menu/edit',
    async ({ data, setIsSubmitted, setIsMenuModal, setIsUpdated }, { getState }) => {
        Toast("Updating Menu ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            data.append('business_id', id);
            const response = await Menu.edit(data, api_token);
            Toast(response.message, "success", false);
            setIsMenuModal({});
            setIsUpdated(true);
        } catch (err) {
            Toast(err.message, "error", false);
            Promise.reject(err.message)
        }
        finally {
            setIsSubmitted(false);
            return;

        }
    }
)

export const deleteMenuThunk = createAsyncThunk(
    'menu/delete',
    async ({ menu_id }, { getState }) => {
        Toast("Deleting Menu ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            const body = {
                business_id: id,
                menu_id
            }
            const response = await Menu.delete(body, api_token);
            Toast(response.message, "success", false);
            return menu_id;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)


const initialState = {
    status: "pending",
    data: [],
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getMenuThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = action.payload;
        },
        [getMenuThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getMenuThunk.rejected]: (state, action) => {
            state.status = "exception"
        },
        [createMenuThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = [
                ...state.data,
                action.payload
            ];
        },
        [createMenuThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [createMenuThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [editMenuThunk.fulfilled]: (state, action) => {
            state.status = "updated";
        },
        [editMenuThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [editMenuThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [deleteMenuThunk.fulfilled]: (state, action) => {
            const menu_id = action.payload;
            const arr = state.data.filter(item => item.id != menu_id);
            state.data = arr;
            state.status = "success";
        },
        [deleteMenuThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [deleteMenuThunk.rejected]: (state, action) => {
            state.status = "error"
        },
    }
})


export const { resetState } = menuSlice.actions

export default menuSlice.reducer