import { configureStore } from '@reduxjs/toolkit';

/** Import Reducers */
import profileReducer from './features/profileReducer';
import postReducer from "./features/postReducer";
import menuReducer from "./features/menuReducer";
import lookupsReducer from './features/lookupsReducer';
import reviewsReducer from './features/reviewsReducer';
import offersReducer from './features/offersReducer';
import dashboardReducer from './features/dashboardReducer';
import reportReducer from './features/reportReducer';

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        post: postReducer,
        menu: menuReducer,
        lookups: lookupsReducer,
        reviews: reviewsReducer,
        offers: offersReducer,
        dashboard: dashboardReducer,
        report: reportReducer
    },
})
