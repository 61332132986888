
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextInput, SelectDropdown } from "../../../components/Fields";
import { createMenuCategoryThunk, getCategoryThunk } from "../../../features/lookupsReducer";
import { IoIosAdd } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import { Toast } from "../../../hooks/useToast";

export function useCreateMenu({ control, errors }) {

    const { status, data } = useSelector(state => state.lookups.data.category);
    const dispatch = useDispatch();
    const categoryRef = useRef();

    const [isSubmitted, setIsSubmitted] = useState(false);


    useEffect(() => {
        if (status !== 'success') {
            dispatch(getCategoryThunk());
        }
    }, [])

    function handleCreateCategory() {
        const category_name = categoryRef.current.value;
        if (!category_name) {
            return Toast('Category Title Should Not Be Empty !!', 'error', false);
        }
        setIsSubmitted(true);
        dispatch(createMenuCategoryThunk({ category_name, setIsSubmitted, categoryRef }));
        return;
    }


    const containerStyle = {
        width: "100%",
        padding: "10px 20px 15px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

    }

    const inputStyle = {
        width: "100%",
        border: "none",
        padding: "5px 20px",
        borderRadius: "20px",
        border: "1px solid lightgrey",
    }

    const buttonStyle = {
        padding: "5px",
        height: "fit-content",
        borderRadius: "100px",
        backgroundColor: "#6268F3",
        border: "none",
        color: "white",
        fontSize: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 0 0 7px",
    }

    const iconStyle = {
        fontSize: "1.5rem"
    }

    const defaultContainer = <div style={containerStyle}>
        <input
            type="text"
            ref={categoryRef}
            placeholder="Category Title..."
            style={inputStyle} />
        {!isSubmitted ?
            <button style={{ ...buttonStyle, cursor: "pointer" }} onClick={handleCreateCategory}><IoIosAdd style={{ ...iconStyle, cursor: "pointer" }} /></button> :
            <button style={{ ...buttonStyle, cursor: "not-allowed" }}><CircularProgress size={25} style={{ color: "white", padding: "5px", cursor: "default", cursor: "not-allowed" }} /></button>
        }

    </div>


    const components = [
        <TextInput
            name="menu_item"
            type="text"
            label="Title"
            control={control}
            error={errors}
        />,
        <SelectDropdown
            name="menu_category"
            label="Select Category"
            showEmptyMessage={false}
            emptyMessage="No Category Found !"
            showDefaultContainer={true}
            defaultContainer={defaultContainer}
            data={data}
            loading={(status != 'success') ? true : false}
            control={control}
            error={errors}
            selectValue="name"

        />,
        <TextInput
            name="menu_description"
            type="text"
            label="Description"
            control={control}
            error={errors}
            multiline={true}
            rows={5}
        />,
        <TextInput
            name="menu_old_price"
            type="number"
            label="$ Price"
            control={control}
            error={errors}
        />,
        <TextInput
            name="menu_new_price"
            type="number"
            label="$ Discounted Price"
            control={control}
            error={errors}
        />


    ]

    return components;
}