import React, { useEffect, useState } from "react";


/**Import Styles And Assets*/
import styles from "./styles/CategoryModal.module.scss";

/**Import Component */
import Modal from "../../components/Modal";
import { TextInput } from "../../components/Fields";
import { createCategorySchema } from "../../Validation";


/**Import Package Components */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";
import { createCategoryThunk } from "../../features/lookupsReducer";



const CreateCategoryModal = ({ setIsModal }) => {
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { handleSubmit, control, formState: { errors } } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(createCategorySchema)
    });

    function onSubmit(body) {
        setIsSubmitted(true);
        dispatch(createCategoryThunk({ category_name: body.category_name, setIsSubmitted, setIsModal }));

    }





    return (
        <Modal>
            <div className={styles.mainDiv}>
                <div className={styles.createPostModal}>
                    <div className={styles.title}>
                        <h1 className={styles.header}>Category Management</h1>
                        <button className={styles.close} onClick={() => !isSubmitted && setIsModal(false)}>X</button>
                    </div>
                    <hr />
                    <div className={styles.content}>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <TextInput
                                name="category_name"
                                type="text"
                                label="Category Title"
                                control={control}
                                error={errors}
                            />
                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`} type="button">Create Category <CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Create Category</button>
                            }
                        </form>
                    </div>
                </div>
            </div >

        </Modal >
    )
}

export default React.memo(CreateCategoryModal);