import React from "react";

/**Import Styles and Assets */
import styles from "./Fields.module.scss"

/**Import Packaged Component*/
import { TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { Controller } from "react-hook-form";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { IoMdArrowDropdown } from "react-icons/io"

export function TimeInput({ name, control, error, label, value, onChange, noMargin }) {

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            className={`${styles.timePicker} ${noMargin && styles.removeMargin}`}
                            InputProps={{ classes: { root: styles.timePickerInput } }}
                            label={label}
                            value={value}
                            onChange={onChange}
                            clearable
                            ampm={false}
                            ampmInClock={false}
                            renderInput={(params) =>
                                <TextField {...params}
                                    InputLabelProps={{
                                        classes: {
                                            focused: styles.focused,
                                            shrink: styles.focused,
                                            root: styles.label,
                                        }
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13) ? e.preventDefault() : ""}
                                />
                            }
                        />
                    </LocalizationProvider>
                )}
            />
            {(error[`${name}`]?.message) && <p style={{ color: "red", fontWeight: 700, fontSize: "0.8rem" }}>{error[`${name}`].message}</p>}
        </>
    )

}

export function DateInput({ name, control, error, label, value, onChange, noMargin, disablePast = false, disableField = false }) {

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            className={`${styles.timePicker} ${noMargin && styles.removeMargin}`}
                            InputProps={{ classes: { root: styles.timePickerInput } }}
                            label={label}
                            value={value}
                            onChange={onChange}
                            closeOnSelect={true}
                            disablePast={disablePast}
                            disabled={disableField}
                            renderInput={(params) =>
                                <TextField {...params}
                                    InputLabelProps={{
                                        classes: {
                                            focused: styles.focused,
                                            shrink: styles.focused,
                                            root: styles.label,
                                        }
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13) ? e.preventDefault() : ""}
                                />
                            }
                        />
                    </LocalizationProvider>
                )}
            />
            {(error[`${name}`]?.message) && <p style={{ color: "red", fontWeight: 700, fontSize: "0.8rem" }}>{error[`${name}`].message}</p>}
        </>
    )

}



export function TextInput({ name, control, type, label, icon, error, onPressEnter, containerStyle, ...props }) {
    function onKeyPress(e) {
        e.preventDefault();
        if (typeof (onPressEnter) === 'function') {
            onPressEnter()
        }
    }
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth classes={{
                        root: containerStyle ? { ...styles.inputContainer, ...containerStyle } : styles.inputContainer
                    }} size="small">
                        <InputLabel classes={{
                            focused: styles.colored,
                            shrink: styles.colored,
                            root: styles.label
                        }}>{label}</InputLabel>
                        <OutlinedInput
                            {...{ ...field, ...props }}
                            type={type}
                            classes={{
                                root: styles.input,
                            }}
                            endAdornment={icon ? <InputAdornment position="end" classes={{
                                root: styles.icon
                            }}>{icon}</InputAdornment> : ""}
                            onKeyDown={(e) => ((e.keyCode == 13) ? onKeyPress(e) : "")}
                        />
                    </FormControl>
                )}
            />
            {(error[`${name}`]?.message) && <p style={{ color: "red", fontWeight: 700, fontSize: "0.8rem" }}>{error[`${name}`].message}</p>}
        </>
    )
}

export function SelectDropdown({
    name,
    label,
    data = [],
    loading = false,
    control,
    error,
    selectValue = 'id',
    emptyMessage = 'Data Not Found',
    showEmptyMessage = true,
    showDefaultContainer = false,
    defaultContainer = <div></div>,
    containerStyle,
    ...props }) {

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth classes={{
                        root: containerStyle ? { ...styles.inputContainer, ...containerStyle } : styles.inputContainer
                    }} size="small">
                        <InputLabel classes={{
                            focused: styles.colored,
                            shrink: styles.colored,
                            root: styles.label
                        }}>{label}</InputLabel>
                        <Select
                            {...field}
                            {...props}
                            IconComponent={loading ? CircularProgress : IoMdArrowDropdown}
                            className={styles.select}
                            onKeyDown={e => e.stopPropagation()}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}

                        >
                            {showDefaultContainer &&
                                <div onKeyDown={e => e.stopPropagation()}>
                                    {defaultContainer}
                                </div>
                            }
                            {!data.length ?
                                (showEmptyMessage && <h6 style={{ textAlign: "center", padding: "10px", userSelect: "none" }}>{emptyMessage}</h6>)
                                :
                                data.map(({ name, id }, index) => {
                                    return <MenuItem value={(selectValue === "id") ? id : name} key={index}>{name}</MenuItem>
                                })}

                        </Select>
                    </FormControl >
                )
                }
            />
            {(error[`${name}`]?.message) && <p style={{ color: "red", fontWeight: 700, fontSize: "0.8rem" }}>{error[`${name}`].message}</p>}
        </>
    )
}


export function CheckboxInput({ name, label, error, ...props }) {
    return (
        <FormControlLabel
            control={
                <Checkbox name={name} size="small"
                    classes={{
                        root: !error ? "" : error[name] ? styles.checkBoxError : "",
                        checked: styles.color,
                    }}
                    {...props}
                    onKeyDown={(e) => (e.keyCode == 13) ? e.preventDefault() : ""}
                />
            }
            label={label}
            classes={{
                root: styles.checkboxLabel
            }}
        />
    )
}


export function FormTextInput({ name, type, label }) {
    return (
        <div classname={styles.field}>
            <TextField name={name} type={type} label={label} size="small" fullWidth={true} classes={{
                root: styles.inputContainer
            }}
                InputLabelProps={{
                    classes: {
                        focused: styles.colored,
                        shrink: styles.colored,
                        root: styles.label
                    }
                }}

                InputProps={{
                    classes: {
                        root: styles.input,

                    }
                }}

            />
        </div>
    )
}

export function FileInput({ name, control, error, ...props }) {

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <input type="file" {...props} />
                )}
            />
            {(error[`${name}`]?.message) && <p style={{ color: "red", fontWeight: 700, fontSize: "0.8rem" }}>{error[`${name}`].message}</p>}
        </>
    )
}