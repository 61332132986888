import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Business } from '../Api/Business';
import { Toast } from '../hooks/useToast';
import CryptoJS from 'crypto-js';
import { updateKey as dashboardUpdate } from './dashboardReducer';

export const postBusinessThunk = createAsyncThunk(
    'profile/post',
    async ({ data, setIsSubmitted, remember_id }, { dispatch }) => {
        Toast("Validating Credentials", "loading", true);
        try {
            const response = await Business.post(data);
            Toast(response.message, "success", false);
            dispatch(updateKey({ isLoggedIn: true, }));
            if (remember_id) {
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(response.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem("web_token", encrypted);

            }
            return response.data
        } catch (err) {
            setIsSubmitted(false);
            Toast(err.message, "error", false);
        }
    }
)

export const createBusinessThunk = createAsyncThunk(
    'profile/create',
    async ({ data, setIsSubmitted }, { dispatch }) => {
        Toast("Validating Credentials", "loading", true);
        try {
            const response = await Business.create(data);
            Toast(response.message, "success", false);
            dispatch(updateKey({ isLoggedIn: true, }));
            return response.data
        } catch (err) {
            setIsSubmitted(false);
            Toast(err.message, "error", false);
        }
    }
)

export const updateBusinessThunk = createAsyncThunk(
    'profile/update',
    async ({ data, setIsSubmitted }, { getState, dispatch }) => {
        Toast("Updating Profile", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Business.update(data, api_token);
            Toast("Profile has been updated successfully", "success", false);
            dispatch(dashboardUpdate({ status: "updated" }));

            const storage = localStorage.getItem("web_token");
            if (storage) {
                localStorage.removeItem('web_token');
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(response.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem("web_token", encrypted);

            }

            return response.data
        } catch (err) {
            Toast("Failed to update profile", "error", false);
        }
        finally { setIsSubmitted(false) }
    }
)

export const updatePasswordThunk = createAsyncThunk(
    'profile/update/password',
    async ({ data, setIsSubmitted }, { getState }) => {
        Toast("Updating Password", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Business.updatePassword(data, api_token);
            Toast(response.message, "success", false);
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
        }
        finally { setIsSubmitted(false) }
    }
)


export const postForgetpasswordThunk = createAsyncThunk(
    'profile/forget-password',
    async ({ data, navigate, setIsSubmitted }, _) => {
        Toast("Sending Email", "loading", true);
        try {
            const response = await Business.postForgetPassword(data);
            Toast(response.message, "success", false);
            navigate(`/sign-in`);
            return;
        } catch (err) {
            setIsSubmitted(false);
            Toast(err.message, "error", false);
        }
    }
)

export const getCategoriesThunk = createAsyncThunk(
    'profile/lookups/categories',
    async () => {
        try {
            const response = await Business.getLookups();
            return response.category
        } catch (err) {
        }
    }
)

export const updateRewardPointsThunk = createAsyncThunk(
    'profile/points/update',
    async ({ points, setIsSubmitted }, { getState, dispatch }) => {
        Toast("Updating reward points", "loading", true);
        try {
            const { api_token, id } = getState().profile.data;
            const body = {
                business_id: id,
                review_points: points
            }
            const response = await Business.updateRewardPoints(body, api_token);
            Toast("Reward points has been updated successfully", "success", false);
            const storage = localStorage.getItem("web_token");
            if (storage) {
                localStorage.removeItem('web_token');
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(response.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem("web_token", encrypted);

            }
            return response.data
        } catch (err) {
            return Toast("Failed to update reward points", "error", false);
        }
        finally { setIsSubmitted(false) }
    }
)


const initialState = {
    status: "pending",
    isLoggedIn: false,
    lookups: {
        categories: {
            status: "pending",
            data: [],
        }
    },
    data: {},
}

export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        updateKey: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        resetState: () => {
            return initialState;
        }

    },
    extraReducers: {
        [postBusinessThunk.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = "success";
        },
        [postBusinessThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [postBusinessThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updatePasswordThunk.fulfilled]: (state, action) => {
            state.status = "success";
        },
        [updatePasswordThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updatePasswordThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateBusinessThunk.fulfilled]: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload
            };
            state.status = "success";
        },
        [updateBusinessThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateBusinessThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [createBusinessThunk.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = "success";
        },
        [createBusinessThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [createBusinessThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [postForgetpasswordThunk.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = "success";
        },
        [postForgetpasswordThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [postForgetpasswordThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [getCategoriesThunk.fulfilled]: (state, action) => {
            state.lookups.categories.data = action.payload;
            state.lookups.categories.status = "success";
        },
        [getCategoriesThunk.pending]: (state, action) => {
            state.lookups.categories.status = "pending"
        },
        [getCategoriesThunk.rejected]: (state, action) => {
            state.lookups.categories.status = "error"
        },
        [updateRewardPointsThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success";
        },
        [updateRewardPointsThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateRewardPointsThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { updateKey, resetState } = businessSlice.actions

export default businessSlice.reducer