import React, { useEffect, useState } from "react";


/**Import Styles And Assets*/
import styles from "./styles/PostModal.module.scss";

/**Import Component */
import Modal from "../../components/Modal";
import { TextInput } from "../../components/Fields";
import { createPostSchema } from "../../Validation";
import { createPostThunk } from "../../features/postReducer";
import { useSocket } from "../../Context/SocketContext";
import { EVENTS } from "../../Config/Socket";


/**Import Package Components */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from "react-redux";



const CreatePostModal = ({ setIsPostModal, setIsUpdated }) => {
    const dispatch = useDispatch();
    const socket = useSocket();
    const { id: business_id } = useSelector(state => state.profile.data);

    const { handleSubmit, control, formState: { errors } } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(createPostSchema)
    });
    const [media, setMedia] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);


    function onSubmit(body) {
        setIsSubmitted(true);

        const socketParams = {
            user_id: business_id,
            group_title: 'title',
            group_type: 'room',
            group_image: '',
            description: '',
            post_id: 0

        }
        socket.emit(EVENTS.CREATE_ROOM, socketParams, (res) => {

            const { post_title, post_description } = body;

            const data = new FormData();
            data.append('room_id', res.id);
            data.append('post_title', post_title);
            data.append('post_description', post_description);
            media.map(file => data.append('uploadedImages', file));
            dispatch(createPostThunk({ data, setIsSubmitted, setIsPostModal, setIsUpdated }));
        });

        socket.dispose(EVENTS.CREATE_ROOM);




    }

    function removeMedia(e) {
        const index = e.target.value;
        const arr = Array.prototype.filter.call(media, (file, ind) => index != ind);
        setMedia(arr);
    }




    return (
        <Modal>
            <div className={styles.mainDiv}>
                <div className={styles.createPostModal}>
                    <div className={styles.title}>
                        <h1 className={styles.header}>Post And Feed Management</h1>
                        <button className={styles.close} onClick={() => !isSubmitted && setIsPostModal(false)}>X</button>
                    </div>
                    <hr />
                    <div className={styles.content}>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <TextInput
                                name="post_title"
                                type="text"
                                label="Post Title"
                                control={control}
                                error={errors}
                            />
                            <TextInput
                                name="post_description"
                                type="text"
                                label="Post Description"
                                control={control}
                                error={errors}
                                multiline={true}
                                rows={4}
                            />
                            <h3 className={styles.uploadHeader}>Upload Images</h3>
                            <div className={styles.uploadContainer}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    className={styles.uploadButton}
                                >
                                    <IoMdAdd />
                                    <input type="file" multiple accept="image/png,image/jpg,image/jpeg,video/mp4,.png,.mp4,.jpg,.jpeg" hidden onChange={(e) => setMedia(prev => [...prev, ...e.target.files])} />
                                </Button>
                                {media.length ? Array.prototype.map.call(media, (file, index) => <div className={styles.imageContainer} key={index}>
                                    <div className={styles.cancel}>
                                        <button value={index} onClick={(e) => { !isSubmitted && removeMedia(e) }} type="button">X</button>
                                    </div>
                                    {
                                        file.type.includes('image') ? <img src={URL.createObjectURL(file)} alt="Uploaded Image" />
                                            : <video width="100" height="100" controls={false} autoPlay={true} muted={true}>
                                                <source src={URL.createObjectURL(file)} type="video/mp4" />
                                                Video Not Supported
                                            </video>
                                    }
                                </div>
                                ) : ""}


                            </div>
                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`} type="button">Create Post <CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Create Post</button>
                            }

                        </form>

                    </div>
                </div>
            </div >

        </Modal >
    )
}

export default React.memo(CreatePostModal);