import React, { useState, useEffect } from "react";

/**Import Components */
import { SelectDropdown, TextInput, TimeInput } from "../../components/Fields";
import Map from "../../components/Map/MapContainer";
import { getCategoriesThunk, updateBusinessThunk } from "../../features/profileReducer";
import { getBase64 } from "../../Prototypes/ImageBase64";

/**Import Styles And Assets */
import styles from "./ManageProfile.module.scss"

/**Import Package Components */
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { manageProfileSchema } from "../../Validation";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { FiMapPin } from "react-icons/fi";
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";

export default function ManageProfile() {
    const base_base64 = "data:image/png;base64,";
    const dispatch = useDispatch();
    const { status, data: categories_data } = useSelector(state => state.profile.lookups.categories);
    const { data: profile } = useSelector(state => state.profile);

    const { id, name, category_id, close_time, description, email, fb_page, geo_location, image_or_video_link, open_time, phone, website } = profile;
    const profile_data = {
        id,
        name,
        category_id,
        description,
        email,
        fb_page,
        geo_location,
        image_or_video_link,
        phone,
        website,
    }

    const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = useForm({
        reValidateMode: "onSubmit",
        defaultValues: profile_data,
        resolver: yupResolver(manageProfileSchema)
    });




    const form_data = watch();
    const geo_locations = watch("geo_location");
    const [isMap, setIsMap] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState({
        open_time: dayjs(open_time, "HH:mm:00"),
        close_time: dayjs(close_time, "HH:mm:00"),
    })
    const [isChanged, setIsChanged] = useState(false);




    useEffect(() => {
        var flag = false;
        Object.keys(form_data).map(key => {
            if (form_data[key] != profile[key]) {
                flag = true;
                return;
            }


        })

        setIsChanged(flag);

    }, [form_data])

    useEffect(() => {
        if (status !== "success") {
            dispatch(getCategoriesThunk());
        }
        if (geo_locations) {
            const location = geo_locations.split(",");
            setValue("geo_location", JSON.stringify({ lat: parseFloat(location[0]), lng: parseFloat(location[1]) }))
        }
    }, [])

    useEffect(() => {
        setValue('open_time', dayjs(data.open_time).format("HH:mm:00"))
        setValue('close_time', dayjs(data.close_time).format("HH:mm:00"))
    }, [data.open_time, data.close_time])




    function handleChange(name, value) {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function onSubmit(data) {
        const { lat, lng } = JSON.parse(data.geo_location);
        let image_url = "";
        if (data?.image_or_video_link) {
            if (data.image_or_video_link.startsWith("http")) {
                image_url = data.image_or_video_link;
            }
            else {
                image_url = data.image_or_video_link.slice(base_base64.length, data.image_or_video_link.length)
            }
        }
        data = {
            ...data,
            image_url,
            geo_location: `${lat},${lng}`
        }
        setIsSubmitted(true);
        dispatch(updateBusinessThunk({ data, setIsSubmitted }));
    }



    async function handleImageChange(e) {

        const file = e.target.files[0];
        getBase64(file)
            .then(result => {
                setValue("image_or_video_link", result)
            })
            .catch(err => {
            });

    };


    return (
        <div className={styles.manageProfile}>
            {isMap ? <Map location={geo_locations ? JSON.parse(geo_locations) : {}} setClose={setIsMap} setLatLng={setValue} /> : ""}
            <div className={styles.container}>
                <h1>Manage Profile</h1>
                <hr />
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={-2} columnSpacing={{ xs: 2 }}>
                        <Grid xs={6} item>
                            <TextInput
                                name="name"
                                type="text"
                                label="Business Name"
                                control={control}
                                error={errors}

                            />
                        </Grid>

                        <Grid xs={6} item>
                            <SelectDropdown
                                name="category_id"
                                type="text"
                                label="Category"
                                control={control}
                                error={errors}
                                data={categories_data}
                                status={status}
                                loading={status !== "success" ? true : false}

                            />
                        </Grid>
                        <Grid xs={6} item>
                            <TextInput
                                name="phone"
                                type="text"
                                label="Phone #"
                                control={control}
                                error={errors}
                                disabled={true}

                            />
                        </Grid>
                        <Grid xs={6} item>
                            <TextInput
                                name="email"
                                type="text"
                                label="Email"
                                autoComplete="no-password"
                                control={control}
                                error={errors}
                                disabled={true}

                            />

                        </Grid>
                        <Grid xs={6} item>
                            <TextInput
                                name="geo_location"
                                type="text"
                                label="Business Location"
                                control={control}
                                error={errors}
                                value={geo_locations ? `${geo_locations}` : ""}
                                icon={<FiMapPin onClick={() => setIsMap(true)} />}
                                onFocus={() => { setIsMap(true) }}
                                autoComplete={false}
                                readOnly={true}

                            />

                        </Grid>
                        <Grid xs={6} item>
                            <TextInput
                                name="fb_page"
                                type="text"
                                label="Facebook Page"
                                control={control}
                                error={errors}
                            />

                        </Grid>
                        <Grid xs={6} item>
                            <TextInput
                                name="website"
                                type="text"
                                label="Website"
                                control={control}
                                error={errors}
                            />

                        </Grid>
                        <Grid xs={6} item >
                            <Grid container rowSpacing={-2} columnSpacing={{ xs: 1 }}>
                                <Grid xs={6} item  >
                                    <TimeInput
                                        name="open_time"
                                        control={control}
                                        error={errors}
                                        type="time"
                                        label="Opening"
                                        value={data.open_time}
                                        onChange={(newValue) => handleChange("open_time", newValue)} />
                                </Grid>
                                <Grid xs={6} item >
                                    <TimeInput
                                        name="close_time"
                                        control={control}
                                        error={errors}
                                        type="time"
                                        label="Closing"
                                        value={data.close_time}
                                        onChange={(newValue) => handleChange("close_time", newValue)} />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid xs={12} item>
                            <TextInput
                                name="description"
                                label="Description"
                                control={control}
                                error={errors}
                                multiline={true}
                                rows={3}

                            />
                        </Grid>
                        <Grid xs={12} item className={styles.uploadContainer}>
                            <p>Upload Image</p>
                            {form_data?.image_or_video_link ?
                                <div className={styles.imageContainer}>
                                    <div className={styles.cancel}>
                                        <button onClick={() => setValue("image_or_video_link", "")}>X</button>
                                    </div>
                                    <img src={form_data.image_or_video_link} alt="Uploaded Image" />
                                </div> :
                                <Button
                                    variant="contained"
                                    component="label"
                                    className={styles.uploadButton}
                                >
                                    <IoMdAdd />
                                    <input type="file" accept="image/png,image/jpg,image/jpeg" hidden onChange={handleImageChange} />
                                </Button>
                            }

                        </Grid>


                        {!isChanged ? <button className={`${styles.submit} ${styles.disabled}`} type="button">Save</button> : isSubmitted ?
                            <button className={`${styles.submit} ${styles.submitted}`} type="button">Save<CircularProgress size={20} /></button>
                            :
                            <button className={styles.submit} type="submit">Save</button>
                        }
                    </Grid>
                </form>

            </div >
        </div >
    )
}