import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";


/**Import Styles And Assets*/
import styles from "./styles/PostModal.module.scss";

/**Import Component */
import Modal from "../../components/Modal";
import { TextInput } from "../../components/Fields";
import { createPostSchema } from "../../Validation";
import { createPostThunk, editPostThunk } from "../../features/postReducer";



/**Import Package Components */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';


const EditPostModal = ({ data, setIsPostModal, setIsUpdated }) => {
    const dispatch = useDispatch();
    const { post_media, post_id } = data;

    const { handleSubmit, control, formState: { errors } } = useForm({
        reValidateMode: "onSubmit",
        defaultValues: data,
        resolver: yupResolver(createPostSchema)
    });


    const [newMedia, setMedia] = useState([]);
    const [uploadedMedia, setUploadedMedia] = useState(post_media);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [deletedMedia, setDeletedMedia] = useState([]);

    const media = useMemo(() => [...newMedia, ...uploadedMedia], [newMedia, uploadedMedia])



    function onSubmit(body) {
        setIsSubmitted(true);

        const { post_title, post_description } = body;

        const data = new FormData();
        data.append('post_id', post_id)
        data.append('post_title', post_title);
        data.append('post_description', post_description);
        newMedia.map(file => data.append('uploadedImages', file));
        data.append('delete_media', deletedMedia.length ? JSON.stringify(deletedMedia) : '');


        dispatch(editPostThunk({ data, setIsSubmitted, setIsPostModal, setIsUpdated }));

    }

    function removeMedia(e) {
        const { value } = e.target;
        const file = JSON.parse(value);
        const type = Object.keys(file)[0];
        let arr = [];

        if (type === 'uploaded') {
            uploadedMedia.forEach(media => {
                if (media.id != file[type]) {
                    arr.push(media);
                }
                else {
                    setDeletedMedia(prev => [...prev, media]);
                }
            })
            setUploadedMedia(arr);

        }
        else {
            arr = newMedia.filter((_, index) => index != file[type]);
            setMedia(arr);
        }


    }


    function handleImageChange(e) {
        setMedia(prev => [...prev, ...e.target.files])
    }


    function getMedia(file) {

        if (file?.thumb !== undefined) {
            return !file.thumb ? <img src={file.url} alt="Uploaded Image" />
                : <video width="100" height="100" controls={false} autoPlay={true} muted={true}>
                    <source src={file.url} type="video/mp4" />
                    Video Not Supported
                </video>
        }
        else {
            return file.type.includes('image') ? <img src={URL.createObjectURL(file)} alt="Uploaded Image" />
                : <video width="100" height="100" controls={false} autoPlay={true} muted={true}>
                    <source src={URL.createObjectURL(file)} type="video/mp4" />
                    Video Not Supported
                </video>
        }

    }

    function setValue(file, index) {
        let obj;
        if (file?.thumb !== undefined) {
            obj = {
                'uploaded': file.id
            }
        }
        else {
            obj = {
                'new': index
            }
        }

        return JSON.stringify(obj);
    }







    return (
        <Modal>
            <div className={styles.mainDiv}>
                <div className={styles.createPostModal}>
                    <div className={styles.title}>
                        <h1 className={styles.header}>Post And Feed Management</h1>
                        <button className={styles.close} onClick={() => !isSubmitted && setIsPostModal({})}>X</button>
                    </div>
                    <hr />
                    <div className={styles.content}>
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <TextInput
                                name="post_title"
                                type="text"
                                label="Post Title"
                                control={control}
                                error={errors}
                            />
                            <TextInput
                                name="post_description"
                                type="text"
                                label="Post Description"
                                control={control}
                                error={errors}
                                multiline={true}
                                rows={4}
                            />
                            <h3 className={styles.uploadHeader}>Upload Images</h3>
                            <div className={styles.uploadContainer}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    className={styles.uploadButton}
                                >
                                    <IoMdAdd />
                                    <input type="file" multiple accept="image/png,image/jpg,image/jpeg,video/mp4" hidden onChange={handleImageChange} />
                                </Button>
                                {media.length ? Array.prototype.map.call(media, (file, index) => <div className={styles.imageContainer} key={index}>
                                    <div className={styles.cancel}>
                                        <button value={setValue(file, index)} onClick={(e) => { !isSubmitted && removeMedia(e) }} type="button">X</button>
                                    </div>
                                    {getMedia(file)}
                                </div>
                                ) : ""}


                            </div>
                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`} type="button">Save<CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Save</button>
                            }

                        </form>

                    </div>
                </div>
            </div >

        </Modal >
    )
}

export default React.memo(EditPostModal);